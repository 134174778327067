import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useTranslation } from 'react-i18next';
import AppLayout from './components/AppLayout';
import { Home } from './pages/Home';
import { Profile } from './pages/profile/Profile';
import { Categories } from './pages/categories/Categories';
import { Items } from './pages/items/Items';
import { Locations } from './pages/locations/Locations';
import { Parties } from './pages/parties/Parties';
import { Documents } from './pages/documents/Documents';
import { Cities } from './pages/cities/Cities';
import { Users } from './pages/users/Users';
import { Roles } from './pages/roles/Roles';
import { Countries } from './pages/countries/Countries';
import { SigninCallback } from './pages/SigninCallback';
import { FormRegister } from './pages/Register';
import { Configuration, ConfigurationParameters, PartyApi, PartyDto } from './api/najd';
import { API_URL, enalbleOtp } from './constants';
import { RepatedDocuments } from './pages/documents/RepatedDocuments';
import { Subdivisions } from './pages/subdivisions/Subdivisions';
import { Reports } from './pages/reports/Rerports';
import { SupplierMonitor } from './pages/monitor/SupplierMonitor';
import { LocationGroups } from './pages/location_groups/LocationGroups';
import { ReportUpload } from './pages/ReportUpload/ReportUpload';
import AsnaniReports from './pages/ReportUpload/AsnaniReports';
import { AsnaniPatients } from './pages/ReportUpload/Patients';
import { ForgetPassword } from './pages/ForgetPassword';
import { SetPassword } from './pages/SetPassword';


function App() {
  const [partyInfo, setPartyInfo] = useState<PartyDto>();

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const auth = useAuth();

  const token = auth.user?.access_token;
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);

  const saveBooleanToLocalStorage = (value: boolean) => {
    localStorage.setItem('otpAuth', JSON.stringify(value));
  };
  const loadBooleanFromLocalStorage = (): boolean | null => {
    if (!enalbleOtp) {
      return true;
    }
    if(partyInfo?.code === 'patient'){
      const value = localStorage.getItem('otpAuth');
      return value ? JSON.parse(value) : null;
    }else{
      return true;
    }
  };
  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      saveBooleanToLocalStorage(false);
      auth.signinRedirect();
      //auth.signinPopup();
    }
    const fetchUserData = async () => {
      try {
        const response = await partyApi.partyGetMy();
        console.log('==> fetchUserData Done : ', response.data);
        setPartyInfo(response.data);
        if (response.data != null) {
          if (response.data.active != true) {
            auth.signoutRedirect();
          }
        }
        // setLoading(false);
      } catch (error) {
        console.error('==> fetchUserData Error : ', error);
      }
    };
    fetchUserData();
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  // new
  if (auth.activeNavigator) {
    return <div>{t('Signing you in/out...')}</div>;
  }
  // if (!auth.isAuthenticated) {
  //     return <div>Unable to log in</div>;
  // }
  // old
  if (auth.isLoading) {
    return <div>{t('Loading')}...</div>;
  }

  if (auth.error) {
    return <div>{t('Oops')}... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {

    const otpAuth = loadBooleanFromLocalStorage();
    return (
      <BrowserRouter>
        {/* <AppLayout> */}
        <Suspense fallback="loading">
          <Routes>
            <Route path="/" element={(otpAuth === false || otpAuth === null) ? <SigninCallback /> : (partyInfo != null ? <AppLayout><Home partyInfo={partyInfo!} /></AppLayout> : <></>)} />
            <Route path="/profile" element={partyInfo != null ? <AppLayout><Profile partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/others/categories/:type" element={partyInfo != null ?<AppLayout><Categories partyInfo={partyInfo!}/></AppLayout> : <></>} />
            <Route path="/others/items" element={partyInfo != null ? <AppLayout><Items partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/others/subdivisions" element={partyInfo != null ?<AppLayout><Subdivisions partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/others/cities" element={partyInfo != null ?<AppLayout><Cities partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/locations" element={partyInfo != null ?<AppLayout><Locations type={undefined} partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/locationGroups" element={partyInfo != null ?<AppLayout><LocationGroups partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/parties/:role" element={partyInfo != null ? <AppLayout><Parties partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/documents/:type" element={partyInfo != null ? <AppLayout><Documents partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/reports/:report" element={partyInfo != null ? <AppLayout><Reports partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/repeat-request" element={partyInfo != null ? <AppLayout><RepatedDocuments partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/supplier-monitor" element={partyInfo != null ? <AppLayout><SupplierMonitor partyInfo={partyInfo!} /></AppLayout> : <></>} />
            <Route path="/administration/users" element={<AppLayout><Users /></AppLayout>} />
            <Route path="/administration/roles" element={<AppLayout><Roles /></AppLayout>} />
            <Route path="/others/nationalities" element={partyInfo != null ?<AppLayout><Countries partyInfo={partyInfo!}  /></AppLayout>: <></>} />
            <Route path="/asnani/report_upload" element={partyInfo != null ?<AppLayout><ReportUpload partyInfo={partyInfo!}  /></AppLayout>: <></>} />
            <Route path="/asnani/locations" element={partyInfo != null ?<AppLayout><Locations type={'asnani'} partyInfo={partyInfo!}  /></AppLayout>: <></>} />
            <Route path="/asnani/patients" element={partyInfo != null ?<AppLayout><AsnaniPatients partyInfo={partyInfo!}  /></AppLayout>: <></>} />
            <Route path="/asnani/users/:role" element={partyInfo != null ?<AppLayout><Parties partyInfo={partyInfo!}  /></AppLayout>: <></>} />
            <Route path="/asnani/refreance/:type" element={partyInfo != null ?<AppLayout><Categories partyInfo={partyInfo!}/></AppLayout> : <></>} />
            <Route path="/signin-callback" element={<SigninCallback />} />
            {/* Additional routes */}
          </Routes>
        </Suspense>
        {/* </AppLayout> */}
      </BrowserRouter>
    );
  } 
  // return (
  //   <BrowserRouter>
  //     {/* <AppLayout> */}
  //     <Suspense fallback="loading">
  //       <Routes>
  //         <Route path="/" element={<FormRegister />} />
  //         <Route path="/forget-password" element={<ForgetPassword />} />
  //         <Route path="/set-password" element={<SetPassword />} />
  //         {/* Additional routes */}
  //       </Routes>
  //     </Suspense>
  //     {/* </AppLayout> */}
  //   </BrowserRouter>
  // );
  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;

}

export default App;
