/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { AsnaniReportFilterDto } from '../../../com/najd/models';
// @ts-ignore
import { ChartStaticsDto } from '../../../com/najd/models';
// @ts-ignore
import { DocumentStatusDetailsReportDto } from '../../../com/najd/models';
// @ts-ignore
import { DocumentSuppliersReportDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { ReportFilterDto } from '../../../com/najd/models';
// @ts-ignore
import { TowMonthChartStaticsDto } from '../../../com/najd/models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportActiveItemReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/active-item-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAsnaniGenderReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/asnani-gender-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AsnaniReportFilterDto} [asnaniReportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAsnaniSttisticReport: async (asnaniReportFilterDto?: AsnaniReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/asnani-statistic-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(asnaniReportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentStatusDetailsReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/document-status-details-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentStatusReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/document-status-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentSuppliersReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/document-suppliers-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGenderReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/gender-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGenderReportV2: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/gender-report-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCategorySalesForYearStatics: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/item-category-sales-for-year-statics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCategorySalesStatics: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/item-category-sales-statics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCountStatics: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/item-count-statics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCountStaticsV2: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/item-count-statics=v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPartyStatusReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/party-status-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityForYearReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/patient-sales-by-city-for-year-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/patient-sales-by-city-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityV2: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/patient-sales-report-v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByGender: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/patient-sales-by-gender`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/patient-sales-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReport: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/request-sales-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReportByLines: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/request-sales-report-by-lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReportByLinesQty: async (reportFilterDto?: ReportFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/request-sales-report-by-lines-qty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSubdivisionReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/subdivision-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTwoMonthRequestReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/documents/two-month-request-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportActiveItemReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportActiveItemReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportAsnaniGenderReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportAsnaniGenderReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AsnaniReportFilterDto} [asnaniReportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportAsnaniSttisticReport(asnaniReportFilterDto?: AsnaniReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportAsnaniSttisticReport(asnaniReportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportDocumentStatusDetailsReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentStatusDetailsReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportDocumentStatusDetailsReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportDocumentStatusReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportDocumentStatusReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportDocumentSuppliersReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentSuppliersReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportDocumentSuppliersReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGenderReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportGenderReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGenderReportV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportGenderReportV2(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportItemCategorySalesForYearStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportItemCategorySalesForYearStatics(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportItemCategorySalesStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportItemCategorySalesStatics(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportItemCountStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportItemCountStatics(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportItemCountStaticsV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportItemCountStaticsV2(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPartyStatusReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPartyStatusReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPatientSalesByCityForYearReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPatientSalesByCityForYearReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPatientSalesByCityReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPatientSalesByCityReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPatientSalesByCityV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPatientSalesByCityV2(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPatientSalesByGender(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPatientSalesByGender(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPatientSalesReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPatientSalesReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportRequestSalesReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportRequestSalesReport(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportRequestSalesReportByLines(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportRequestSalesReportByLines(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportRequestSalesReportByLinesQty(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportRequestSalesReportByLinesQty(reportFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportSubdivisionReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportSubdivisionReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportTwoMonthRequestReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TowMonthChartStaticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportTwoMonthRequestReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportActiveItemReport(options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportActiveItemReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAsnaniGenderReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportAsnaniGenderReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AsnaniReportFilterDto} [asnaniReportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAsnaniSttisticReport(asnaniReportFilterDto?: AsnaniReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportAsnaniSttisticReport(asnaniReportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentStatusDetailsReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<DocumentStatusDetailsReportDto>> {
            return localVarFp.reportDocumentStatusDetailsReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentStatusReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportDocumentStatusReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDocumentSuppliersReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<DocumentSuppliersReportDto>> {
            return localVarFp.reportDocumentSuppliersReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGenderReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportGenderReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGenderReportV2(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportGenderReportV2(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCategorySalesForYearStatics(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportItemCategorySalesForYearStatics(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCategorySalesStatics(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportItemCategorySalesStatics(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCountStatics(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportItemCountStatics(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportItemCountStaticsV2(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportItemCountStaticsV2(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPartyStatusReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPartyStatusReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityForYearReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPatientSalesByCityForYearReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPatientSalesByCityReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByCityV2(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPatientSalesByCityV2(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesByGender(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPatientSalesByGender(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPatientSalesReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportPatientSalesReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReport(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportRequestSalesReport(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReportByLines(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportRequestSalesReportByLines(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportFilterDto} [reportFilterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportRequestSalesReportByLinesQty(reportFilterDto?: ReportFilterDto, options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportRequestSalesReportByLinesQty(reportFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSubdivisionReport(options?: any): AxiosPromise<Array<ChartStaticsDto>> {
            return localVarFp.reportSubdivisionReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTwoMonthRequestReport(options?: any): AxiosPromise<Array<TowMonthChartStaticsDto>> {
            return localVarFp.reportTwoMonthRequestReport(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportActiveItemReport(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportActiveItemReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportAsnaniGenderReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportAsnaniGenderReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AsnaniReportFilterDto} [asnaniReportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportAsnaniSttisticReport(asnaniReportFilterDto?: AsnaniReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportAsnaniSttisticReport(asnaniReportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportDocumentStatusDetailsReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportDocumentStatusDetailsReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportDocumentStatusReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportDocumentStatusReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportDocumentSuppliersReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportDocumentSuppliersReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportGenderReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportGenderReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportGenderReportV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportGenderReportV2(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportItemCategorySalesForYearStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportItemCategorySalesForYearStatics(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportItemCategorySalesStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportItemCategorySalesStatics(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportItemCountStatics(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportItemCountStatics(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportItemCountStaticsV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportItemCountStaticsV2(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPartyStatusReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPartyStatusReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPatientSalesByCityForYearReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPatientSalesByCityForYearReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPatientSalesByCityReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPatientSalesByCityReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPatientSalesByCityV2(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPatientSalesByCityV2(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPatientSalesByGender(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPatientSalesByGender(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportPatientSalesReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportPatientSalesReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportRequestSalesReport(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportRequestSalesReport(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportRequestSalesReportByLines(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportRequestSalesReportByLines(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportFilterDto} [reportFilterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportRequestSalesReportByLinesQty(reportFilterDto?: ReportFilterDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportRequestSalesReportByLinesQty(reportFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportSubdivisionReport(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportSubdivisionReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportTwoMonthRequestReport(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportTwoMonthRequestReport(options).then((request) => request(this.axios, this.basePath));
    }
}
